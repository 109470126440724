import { Shift } from './Shift/Shift.tsx';
import { Map } from './Map/Map.tsx';
import { useQueries, useQuery } from '@tanstack/react-query';
import { getGeography } from '../../queries/getGeography.ts';
import { Kpi } from './Kpi/Kpi.tsx';
import { Roadmap } from './Roadmap/Roadmap.tsx';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Mission } from './Mission/Mission.tsx';
import { getWebsocketToken } from '../../queries/getWebsocketToken.ts';
import { getTerritoryConfiguration } from '../../utils.ts';
import { getShift, getVehicles } from '../../queries/getVehicles.ts';
import { TerritoryConfig } from '../../interfaces/TerritoryConfig.ts';
import { SelectedVehicleContext } from '../../contexts/SelectedVehicleContext.tsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { websocketUrl } from '../../queries/constants.ts';
import { processVehicleMessage } from '../../websocket/processing.ts';
import { IVehicle } from '../../interfaces/Vehicle.ts';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

interface Props {
  territorySlug: string;
}
export function Dashboard(props: Props) {
  const [territoryConfig, setTerritoryConfig] = useState<TerritoryConfig | null>();

  const [vehicles, setVehicles] = useState<IVehicle[] | undefined>([]);
  const navigate = useNavigate();
  const [vehiclesNameList, setVehiclesNameList] = useState<string[] | undefined>([]);

  const { selectedVehicle } = useContext(SelectedVehicleContext);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  const { data: geography, error: geographyError } = useQuery({
    queryKey: ['geography', props.territorySlug],
    queryFn: () => getGeography(props.territorySlug),
  });

  const { data: websocketToken } = useQuery({
    queryKey: ['websocketToken'],
    queryFn: () => getWebsocketToken(),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
  });

  const { data: vehiclesData } = useQuery({
    queryKey: ['vehicles', props.territorySlug],
    queryFn: () => getVehicles(props.territorySlug),
    refetchInterval: 10000,
    retry: false,
  });

  useQueries({
    queries: vehiclesData
      ? vehiclesData.data.map((vehicle: IVehicle) => {
          vehicle['shift'] = {
            bus: 0,
            end_datetime: '',
            id: 0,
            ride: 0,
            start_datetime: '',
            status: '',
          };
          return {
            queryKey: ['shift', vehicle.id],
            refetchInterval: 10000,
            queryFn: () => getShift(props.territorySlug, vehicle.id),
            select: (shift: { data: never }) => (vehicle['shift'] = shift.data[0]),
            retry: false,
            useErrorBoundary: false,
          };
        })
      : [],
  });

  useEffect(() => {
    setVehiclesNameList(vehicles?.map((vehicle: IVehicle) => vehicle.provider_identifier));
    if (
      readyState === ReadyState.OPEN &&
      websocketToken &&
      vehiclesNameList !== undefined &&
      vehiclesNameList.length > 0
    ) {
      sendJsonMessage({
        type: 'init',
        auth_token: websocketToken?.data.WEBSOCKET_TOKEN,
        object_type: 'vehicle_states',
        object_id_list: vehiclesNameList,
      });
    }
  }, [readyState, websocketToken, vehicles]);

  useEffect(() => {
    if (lastJsonMessage?.message_type === 'vehicle_states' && vehicles !== undefined) {
      processVehicleMessage(lastJsonMessage, vehicles);
      setVehicles(vehicles);
    } else {
      setVehicles(vehiclesData?.data);
    }
  }, [lastJsonMessage, vehiclesData?.data]);

  useEffect(() => {
    if (vehiclesData?.data) {
      getTerritoryConfiguration(props.territorySlug).then(res => {
        setTerritoryConfig(res);
      });
    }
  }, [props, vehiclesData?.data]);

  useEffect(() => {
    const error = geographyError as AxiosError;
    if (
      error !== null &&
      error !== undefined &&
      error.response?.status &&
      error.response?.status >= 500
    ) {
      navigate('/server-error/');
    }
  }, [geographyError]);

  return (
    <Container className={'dashboard-container'}>
      <Row>
        <Col sm={3} className={'pe-0 dashboard-col'}>
          <Shift
            websocketToken={websocketToken?.data}
            territorySlug={props.territorySlug}
            geography={geography?.data}
            territoryConfig={territoryConfig}
            vehiclesList={vehicles}
          />
        </Col>
        <Col sm={6} className={'dashboard-col'}>
          <div>
            {selectedVehicle?.id === undefined ? (
              <Kpi />
            ) : (
              <>
                <Roadmap territorySlug={props.territorySlug} />
              </>
            )}
          </div>
          <Map vehicles={vehicles} territorySlug={props.territorySlug} geography={geography?.data} />
        </Col>
        <Col sm={3} className={'ps-0 dashboard-col'}>
          <Mission
            websocketToken={websocketToken?.data}
            territorySlug={props.territorySlug}
            territoryConfig={territoryConfig}
          />
        </Col>
      </Row>
    </Container>
  );
}
